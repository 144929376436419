import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import JotformEmbed from 'react-jotform-embed';


function App() {
  return (
    <div style={{height: "100%"}}>
      <JotformEmbed src="https://form.jotform.com/242597551022859" />
    </div>
    
  );
}

export default App;